<template>
  <div class="page-common-body user-home-page-container" ref="wrapper">
    <div class="page-main-body">
      <div class="user-name-setting" v-if="user">
        <div class="title">
          <template v-if="user.username"
            >欢迎来到 ChicForgo 馥馥小高，尊敬经的{{
              user.username.firstName + "" + user.username.lastName
            }}{{
              Number(user.gender) > 0
                ? Number(user.gender) === 1
                  ? "先生"
                  : "女士"
                : ""
            }}</template
          >
          <template v-else>
            欢迎您，该怎么称呼你？
          </template>
          <p class="bindWxHint" v-if="!user.isBindWx" @click="onBindWx">
            为了更方便的使用，建议您绑定<strong>微信</strong>
          </p>
        </div>
        <el-form
          :model="form"
          class="user-base-info-setting-form"
          v-if="user.username === ''"
          ref="editUserInfoForm"
          :rules="rules"
        >
          <el-form-item prop="gender" class="setting__item">
            <el-select class="--theme-gray" v-model="form.gender">
              <el-option
                v-for="(item, index) in sexOptions"
                :key="index"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="firstName" class="setting__item">
            <el-input v-model="form.firstName" placeholder="姓"></el-input>
          </el-form-item>
          <el-form-item prop="lastName" class="setting__item">
            <el-input v-model="form.lastName" placeholder="名"></el-input>
          </el-form-item>
          <el-form-item class="setting__item">
            <el-button
              type="submit"
              :loading="editLoading"
              @click="updateUserInfo"
              >保存</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <div class="user-menus">
        <div class="menu__inner">
          <router-link
            class="menu__item"
            v-for="(item, index) in meuns"
            :key="index"
            :to="{
              path: `${item.link}`
            }"
          >
            <div class="item__header">{{ item.title }}</div>
            <div class="item__body">
              <div class="icon-box">
                <img :src="item.icon.normal" alt="" />
                <img :src="item.icon.hover" alt="" />
              </div>
              <p>{{ item.text }}</p>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
export default {
  name: "page-user-home",
  data() {
    return {
      form: {
        gender: "",
        firstName: "",
        lastName: ""
      },
      rules: {
        gender: [
          {
            required: true,
            message: "请选择您的性别",
            trigger: "blur"
          }
        ],
        firstName: [
          {
            required: true,
            message: "请填写您的姓名",
            trigger: "blur"
          }
        ],
        lastName: [
          {
            required: true,
            message: "请填写您的姓名",
            trigger: "blur"
          }
        ]
      },
      sexOptions: [
        {
          value: 1,
          label: "先生"
        },
        {
          value: 2,
          label: "女士"
        }
      ],
      meuns: [
        {
          title: "订单信息",
          text: "管理和编辑您的订单",
          icon: {
            normal: require("@/assets/images/shop/icon-my-address-normal.png"),
            hover: require("@/assets/images/shop/icon-my-address-hover.png")
          },
          link: "/orders"
        },
        {
          title: "心愿单",
          text: "搜集您所喜爱的精品",
          icon: {
            normal: require("@/assets/images/shop/icon-collect-normal.png"),
            hover: require("@/assets/images/shop/icon-collect-hover.png")
          },
          link: "/collects"
        },
        {
          title: "我的地址",
          text: "管理您的寄送和账单地址",
          icon: {
            normal: require("@/assets/images/shop/icon-located-normal.png"),
            hover: require("@/assets/images/shop/icon-located-hover.png")
          },
          link: "/address"
        }
      ],
      editLoading: false
    };
  },
  computed: {
    ...mapState({
      user: state => state.user.user
    })
  },
  watch: {
    user(val) {
      console.log(val);
    }
  },
  mounted() {
    const that = this;
    that.heightFullScreen(that.$refs.wrapper, true);
  },
  methods: {
    ...mapMutations("user", ["SET_STATE"]),
    updateUserInfo() {
      const that = this;
      that.$refs.editUserInfoForm.validate(valid => {
        if (valid) {
          that.editLoading = true;
          that.$request
            .post("/api/user/account/update-info", that.form)
            .then(res => {
              this.SET_STATE(res.data);
              that.$message({
                message: "修改成功",
                type: "success"
              });
            })
            .catch(err => {
              console.log(err);
            })
            .finally(() => {
              that.editLoading = false;
            });
        }
      });
    },
    onBindWx() {}
  }
};
</script>

<style></style>
